//@ts-nocheck
import { useParams, useNavigate } from "react-router";
import { useQuery } from "react-query";

import Spinner from "react-bootstrap/esm/Spinner";
import Table from "react-bootstrap/esm/Table";
import { listTeam, QUERY_KEY as TeamQueryKey } from "../data/team";
import Page from "../Page";
import chunk from "lodash/chunk";
import Button from "react-bootstrap/esm/Button";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import classNames from "classnames";
import { FestDetails } from "../utils/static";
import { dateStrForFestival } from "../utils/date";

const EventDetail = () => {
  const { id } = useParams();
  const { data: teamsResponse, ...teamsMeta } = useQuery(
    [TeamQueryKey, { event: id }],
    listTeam,
    { staleTime: Infinity, enabled: !!id }
  );
  const componentToPrint = useRef();
  const handleOnPrint = useReactToPrint({
    //@ts-ignore
    content: () => componentToPrint.current,
    documentTitle: `${FestDetails.name} - Event wise report`,
    pageStyle: "@page { size: landscape; };",
  });

  const StudentCell = ({ team, student }: { team: any; student: any }) => (
    <td>
      <div className="d-flex align-items-center">
        <img
          src={student.photo}
          alt={student.name}
          width="50px"
          className="img-fluid me-2"
        ></img>
        <div>
          <strong>{student.name}</strong>
          <br />
          <small>{student.class_name}</small>
          <br />
          <small>{student.phone_number}</small>
          <br />
          {student.is_accompanying && (
            <span class="badge rounded-pill bg-primary">Accompany</span>
          )}
          <div
            className={classNames("d-flex align-items-center", [
              team.verified_student_ids.includes(student.id)
                ? "text-success"
                : "text-danger",
            ])}
          >
            <span className="material-icons">
              {team.verified_student_ids.includes(student.id)
                ? "verified"
                : "error"}
            </span>
          </div>
        </div>
      </div>
    </td>
  );

  const navigate = useNavigate();
  const firstRow = teamsResponse?.data.results[0];

  return (
    <Page title="Event Detail">
      <div className="d-flex justify-content-between">
        <Button onClick={() => navigate(-1)} className="mb-2">
          Back
        </Button>
        <Button onClick={handleOnPrint} className="mb-2">
          Print
        </Button>
      </div>
      {teamsMeta.isLoading && <Spinner animation="border" />}
      {!teamsMeta.isLoading && (
        <div ref={componentToPrint}>
          {/* <div className="print-only">
            <div className="d-flex align-items-center">
              <div>
                <img src={FestDetails.logo} alt="logo" width={"100px"} />
              </div>
              <div className="ms-4">
                <h1>{FestDetails.name}</h1>
                <h3>{dateStrForFestival(FestDetails)}</h3>
              </div>
            </div>
          </div>
          <br className="print-only" />
          <br className="print-only" /> */}
          <h3>{firstRow?.event.name}</h3>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>College</th>
                <th colSpan={Math.min(firstRow?.students.length, 2)}>
                  Participants
                </th>
                <th className="print-only">Chest number</th>
                <th className="print-only">Lot number</th>
                <th className="print-only">Signature</th>
              </tr>
            </thead>
            <tbody>
              {(teamsResponse?.data.results || []).map(
                (team: any, i: number) => {
                  let allStudents = team.students;
                  for (
                    let i = 0;
                    i < (team.accompany_students ?? []).length;
                    i++
                  ) {
                    allStudents.push({
                      ...team.accompany_students[i],
                      is_accompanying: true,
                    });
                  }
                  const studentGroups = chunk(allStudents, 2);
                  return (
                    <>
                      <tr>
                        <td valign="middle" rowSpan={studentGroups.length}>
                          {i + 1}
                        </td>
                        <td valign="middle" rowSpan={studentGroups.length}>
                          {team.college.name}
                        </td>
                        {studentGroups[0].map((student) => (
                          <StudentCell team={team} student={student} />
                        ))}
                        <td
                          rowSpan={studentGroups.length}
                          className="print-only"
                        ></td>
                        <td
                          rowSpan={studentGroups.length}
                          className="print-only"
                        ></td>
                        <td
                          rowSpan={studentGroups.length}
                          className="print-only"
                        ></td>
                      </tr>
                      {studentGroups.slice(1).map((chunk) => (
                        <tr>
                          {chunk.map((student) => (
                            <StudentCell team={team} student={student} />
                          ))}
                        </tr>
                      ))}
                    </>
                  );
                }
              )}
            </tbody>
          </Table>
        </div>
      )}
    </Page>
  );
};

export default EventDetail;
