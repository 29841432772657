//@ts-nocheck
import Form from "react-bootstrap/Form";
import { useQuery } from "react-query";
import { listEvents, QUERY_KEY as EventQueryKey } from "../data/event";
import Spinner from "react-bootstrap/esm/Spinner";
import { Typeahead } from "react-bootstrap-typeahead";

const EventSelect: React.FC = (props) => {
  const { data: events, ...eventMeta } = useQuery(EventQueryKey, listEvents, {
    staleTime: Infinity,
  });
  if (eventMeta.isLoading || eventMeta.isFetching)
    return <Spinner animation="border" />;

  const options = (events?.data.results || []).map((e) => ({
    id: e.id,
    label: e.name,
    max_participants: e.max_participants,
  }));
  const defaultSelected = props.value
    ? options.filter((o) => o.id === props.value)
    : undefined;
  if (props.value && !defaultSelected) return <Spinner animation="border" />;

  return (
    <Form.Group>
      <Form.Label>
        <b>Event</b>
      </Form.Label>
      <Typeahead
        id="event-select"
        name="event"
        options={options}
        onChange={props.onChange}
        placeholder="Search the event"
        disabled={props.disabled}
        selected={defaultSelected}
        clearButton
      />
      <Form.Text>
        Name of the event will be the Manglish version of the name in the
        Manual. Eg: പ്രസംഗം &#8594; Prasangam
      </Form.Text>
    </Form.Group>
  );
};

export default EventSelect;
