import { noAuthAxios, authAxios } from "./api";

export const QUERY_KEY = "results";
export const BASE_URL = "app/results/";

export const listResults = ({ queryKey }: { queryKey: any }) => {
  const params = queryKey[1];
  const queryParams = Object.keys(params)
    .map((p) => `${p}=${params[p]}`)
    .join("&");
  const url = BASE_URL + (queryParams ? `?${queryParams}` : "");
  return noAuthAxios.get(url);
};

export const addResult = (data: any) => authAxios.post(BASE_URL, data);
export const deleteResult = (resultId: number) =>
  authAxios.delete(BASE_URL + `${resultId}/`);

export const detailResult = ({ queryKey }: { queryKey: any }) => {
  const resultId = queryKey[1];
  return noAuthAxios.get(`${BASE_URL}${resultId}/`);
};

export const generateCertificate = (id: string) => {
  return authAxios.get(`${BASE_URL}${id}/generate_certificate/`);
};
