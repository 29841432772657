//@ts-nocheck
import Page from "../Page";
import EventSelect from "../components/EventSelect";
import { useState } from "react";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import Alert from "react-bootstrap/esm/Alert";
import debounce from "lodash/debounce";
import keyBy from "lodash/keyBy";

import { useQuery, useQueryClient } from "react-query";
import { QUERY_KEY as StudentQueryKey, listStudents } from "../data/student";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { QUERY_KEY as EventQueryKey, listEvents } from "../data/event";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import {
  saveTeam,
  QUERY_KEY as TeamQueryKey,
  getTeamDetail,
  useHasConfirmed,
} from "../data/team";
import Spinner from "react-bootstrap/esm/Spinner";
import { useNavigate } from "react-router";
import { useQueryParams } from "../utils/location";
import HasConfirmedBlocker from "./HasConfirmedBlocker";
import {
  BaseApiStatus,
  LoadingStatus,
  setApiStatus,
  TApiStatusResponse,
} from "../utils/apiStatus";

const NewTeam: React.FC = () => {
  const confirmationStatus = useHasConfirmed();
  const [searchQuery, setSearchQuery] = useState(undefined);
  const [accompanyMethod, setAccompanyMethod] = useState(undefined);
  const [saveStatus, setSaveStatus] =
    useState<TApiStatusResponse>(BaseApiStatus);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const queryParams = useQueryParams();
  const teamId = queryParams.get("team_id");
  const isEditPage = !!teamId;

  const getParams = () => {
    const params = {};
    if (searchQuery) {
      params.search = searchQuery;
    }
    return params;
  };
  const { data: students, ...studentsMeta } = useQuery(
    [StudentQueryKey, getParams()],
    listStudents,
    {
      staleTime: Infinity,
    }
  );
  const { data: events } = useQuery(EventQueryKey, listEvents, {
    staleTime: Infinity,
  });
  const { data: team, ...teamDetailMeta } = useQuery(
    [TeamQueryKey, teamId],
    getTeamDetail,
    { staleTime: Infinity, enabled: !!teamId }
  );

  const eventsMap = keyBy(events?.data.results || [], "id");
  const isLoading = teamDetailMeta.isLoading || teamDetailMeta.isFetching;

  let edittingEvent = null;
  let edittingStudents = [];
  if (
    teamId &&
    teamDetailMeta.isFetched &&
    Object.keys(eventsMap).length !== 0
  ) {
    edittingEvent = team?.data.event.id;
    edittingStudents = team?.data.students.map((s) => s.id);
  }

  const [event, setEvent] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedAccompanyStudents, setSelectedAccompanyStudents] = useState(
    []
  );

  const debouncedSearch = debounce((query) => {
    setSearchQuery(query);
  }, 250);

  const handleEventChange = (e) => {
    setEvent(e[0]?.id);
  };

  const handleStudentSelect = (selected) => {
    console.log(`DEBUG "here"': ${JSON.stringify(selected)}`);
    setSelectedStudents(selected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (confirmationStatus.hasConfirmed) {
      return;
    } else {
      setSaveStatus(LoadingStatus);
      const dataJson = {};
      dataJson.students = selectedStudents.map((s) => s.id);
      dataJson.accompany_students = selectedAccompanyStudents.map((s) => s.id);
      dataJson.accompany_method = accompanyMethod;
      dataJson.event = event || edittingEvent;

      if (edittingEvent) {
        dataJson.teamId = teamId;
      }

      if (dataJson.students.length === 0) {
        setSaveStatus(BaseApiStatus);
        setTimeout(() => {
          navigate("/app/teams/");
        }, 2000);
        return;
      }

      setApiStatus(saveTeam, dataJson, (response) => {
        setSaveStatus(response);
        if (response.success) {
          queryClient.invalidateQueries(TeamQueryKey);
          queryClient.invalidateQueries(StudentQueryKey);
          setTimeout(() => {
            navigate("/app/teams/");
          }, 2000);
        }
      });
    }
  };

  const buildStudentOption = (s) => ({
    ...s,
    label: `${s.name} (${s.class_name})`,
  });

  const options = (students?.data.results || []).map((s) =>
    buildStudentOption(s)
  );
  const selectedStudentOptions = edittingStudents
    ? team?.data.students.map((s) => buildStudentOption(s))
    : [];
  const thisEvent = eventsMap[event || team?.data.event.id];

  if (isLoading) return <Spinner animation="border" />;

  return (
    <Page title={isEditPage ? "Edit Team" : "Add Team"}>
      <HasConfirmedBlocker confirmationStatus={confirmationStatus} />
      <Form onSubmit={handleSubmit}>
        <fieldset disabled={confirmationStatus.hasConfirmed}>
          <Row>
            <Col xs={12} md={6}>
              <Row className="mt-2">
                <EventSelect
                  onChange={handleEventChange}
                  disabled={isEditPage}
                  value={thisEvent?.id}
                />
              </Row>
              <Row className="mt-2">
                <fieldset disabled={!thisEvent}>
                  <Form.Group>
                    <Form.Label>
                      <b>
                        Select Team Members{" "}
                        {thisEvent?.max_participants > 1
                          ? `(Upto ${thisEvent?.max_participants} members)`
                          : ""}
                      </b>
                    </Form.Label>
                    <AsyncTypeahead
                      id="new-team-students"
                      name="students"
                      multiple={thisEvent?.max_participants > 1}
                      isLoading={isLoading}
                      onChange={handleStudentSelect}
                      onSearch={debouncedSearch}
                      // selected={selectedStudentOptions}
                      options={options}
                      placeholder="Search for student"
                      inputProps={{
                        min: thisEvent?.min_participants,
                        max: thisEvent?.max_participants,
                      }}
                      clearButton
                      disabled={!thisEvent}
                    />

                    {isEditPage ? (
                      <Form.Text>
                        Existing team members:
                        <>
                          {(team?.data?.students || []).map((s, i) => (
                            <>
                              <strong>{s.name}</strong> ({s.class_name})
                              {i !== team?.data?.students?.length - 1
                                ? ", "
                                : ""}
                            </>
                          ))}
                        </>
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                </fieldset>
              </Row>
              {thisEvent?.can_use_accompany && (
                <Row className="mt-3">
                  <Form.Group>
                    <Form.Label>
                      <b>Choose the type of accompany</b>
                    </Form.Label>
                    <br />
                    <Form.Check inline type="radio">
                      <Form.Check.Input
                        type="radio"
                        value="students"
                        name="accompany_method"
                        onChange={(e) => setAccompanyMethod(e.target.value)}
                      />
                      <Form.Check.Label>Use Students</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline type="radio">
                      <Form.Check.Input
                        type="radio"
                        value="professionals"
                        name="accompany_method"
                        onChange={(e) => setAccompanyMethod(e.target.value)}
                      />
                      <Form.Check.Label>Use Professionals</Form.Check.Label>
                    </Form.Check>
                    <Form.Check inline type="radio">
                      <Form.Check.Input
                        type="radio"
                        value="cd_or_usb"
                        name="accompany_method"
                        onChange={(e) => setAccompanyMethod(e.target.value)}
                      />
                      <Form.Check.Label>Use CD / USB</Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Row>
              )}
              {accompanyMethod && accompanyMethod === "students" && (
                <Row className="mt-3">
                  <Form.Group>
                    <Form.Label>
                      <b>Select accompanying students</b>
                      {thisEvent?.max_accompany_students > 1
                        ? `(Upto ${thisEvent?.max_accompany_students} members)`
                        : ""}
                    </Form.Label>
                    <AsyncTypeahead
                      id="new-team-accompany-students"
                      name="accompany_students"
                      multiple={thisEvent?.max_accompany_students > 1}
                      isLoading={isLoading}
                      onChange={setSelectedAccompanyStudents}
                      onSearch={debouncedSearch}
                      options={options}
                      placeholder="Search for student"
                      inputProps={{
                        min: 1,
                        max: thisEvent?.max_accompany_students,
                      }}
                      clearButton
                      disabled={!thisEvent}
                    />
                  </Form.Group>
                </Row>
              )}
              <Row className="mt-3">
                <Form.Group>
                  <Button
                    variant="secondary"
                    type="button"
                    disabled={saveStatus.loading}
                    onClick={() => navigate(-1)}
                    className="me-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      saveStatus.loading || selectedStudents.length === 0
                    }
                  >
                    {isEditPage ? "Save Team" : "Create Team"}
                    {saveStatus.loading && (
                      <Spinner animation="border" size="sm" />
                    )}
                  </Button>
                </Form.Group>
              </Row>
              <Row className="mt-2">
                {!!saveStatus.success ? (
                  <Alert variant="success">
                    <strong>
                      Team for <strong>{eventsMap[event]?.name}</strong> saved.{" "}
                      <br />
                    </strong>
                    <p>
                      {selectedStudents.length ? (
                        <>
                          <div>
                            Members:
                            <ol>
                              {(selectedStudents || []).map((student) => (
                                <li>
                                  {student.name}, {student.class_name}
                                </li>
                              ))}
                            </ol>
                          </div>
                        </>
                      ) : null}
                      Redirecting to teams page in 2s.
                    </p>
                  </Alert>
                ) : null}
                {!!saveStatus.error ? (
                  <Alert variant="danger">
                    <strong>Save failed</strong>
                    <p>
                      {saveStatus.error.response && (
                        <>
                          Following issues needs to be fixed.
                          <ul>
                            {Object.keys(saveStatus.error.response).map(
                              (key) => (
                                <li>
                                  {key !== "non_field_errors" ? (
                                    <span className="text-capitalize">
                                      {key}
                                    </span>
                                  ) : null}
                                  {saveStatus.error?.response[key]}
                                </li>
                              )
                            )}
                          </ul>
                        </>
                      )}
                    </p>
                  </Alert>
                ) : null}
              </Row>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </Page>
  );
};
export default NewTeam;
