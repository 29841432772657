//@ts-nocheck

import { authAxios } from "./api";
import { useQuery } from "react-query";

export const QUERY_KEY = "teams";
export const CONFIRMATION_QUERY_KEY = `${QUERY_KEY}/has_confirmed`;
export const BASE_URL = "app/teams/";

export const listTeam = ({ queryKey }) => {
  const params = queryKey[1];
  const queryParams = Object.keys(params)
    .map((p) => `${p}=${params[p]}`)
    .join("&");
  const url = BASE_URL + (queryParams ? `?${queryParams}` : "");
  return authAxios.get(url);
};

export const getTeamDetail = ({ queryKey }) => {
  const teamId = queryKey[1];
  return authAxios.get(`${BASE_URL}${teamId}/`);
};

export const saveTeam = ({ teamId, ...data }) => {
  if (!teamId) return authAxios.post(BASE_URL, data);
  else return authAxios.patch(`${BASE_URL}${teamId}/`, data);
};

export const deleteTeam = (teamId) => authAxios.delete(`${BASE_URL}${teamId}/`);

export const hasConfirmation = () => authAxios.get(`${BASE_URL}has_confirmed/`);
export const confirm = (data) =>
  authAxios.post(`${BASE_URL}confirm_submission/`, data);
export const printoutConfirmation = () =>
  authAxios.get(`${BASE_URL}printout_url/`);

export const useHasConfirmed = () => {
  const { data: hasConfirmationResponse, ...hasConfirmationMeta } = useQuery(
    CONFIRMATION_QUERY_KEY,
    hasConfirmation,
    { staleTime: Infinity }
  );
  return {
    isLoading: hasConfirmationMeta.isLoading || hasConfirmationMeta.isFetcing,
    hasConfirmed: hasConfirmationResponse?.data.has_confirmed,
  };
};

export const teamStats = () => authAxios.get(`${BASE_URL}stats/`);
export const reports = () => authAxios.get(`${BASE_URL}reports/`);
