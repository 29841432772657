// @ts-nocheck
import { useEffect, useRef } from "react";
import { Button, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  listStudentsForPrintout,
  QUERY_KEY as StudentsQueryKey,
} from "../data/student";
import { listTeam, QUERY_KEY as TeamsQueryKey } from "../data/team";
import { dateStrForFestival } from "../utils/date";
import { FestDetails } from "../utils/static";
import { useSearchParams } from "react-router-dom";
import * as changeCase from "change-case";

const PrintOutPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const college_id = searchParams.get("collegeId");

  const getParams = () => {
    if (college_id) return { college_id, page_size: 250 };
    else return { page_size: 250 };
  };

  const { data: allStudents, isLoading: isStudentsLoading } = useQuery(
    [StudentsQueryKey + "forPrintout", getParams()],
    listStudentsForPrintout
  );
  const { data: allTeams, isLoading: isTeamsLoading } = useQuery(
    [TeamsQueryKey, getParams()],
    listTeam
  );

  const collegeName = allStudents?.data.results[0]?.college.name;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: `${FestDetails.name} - ${collegeName}`,
    pageStyle: "@page { size: potrait; }",
  });

  const handleBack = () => {
    navigate("/app/print");
  };

  useEffect(() => {
    if (!isStudentsLoading && !isTeamsLoading) {
      // handlePrint();
    }
  }, [isStudentsLoading, isTeamsLoading]);

  return (
    <div>
      <div className="d-flex justify-content-center">
        <Button
          className="me-2"
          size="sm"
          variant="primary"
          onClick={handlePrint}
        >
          Print
        </Button>
        <Button
          className="me-2"
          size="sm"
          variant="primary"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <hr />
      <div ref={componentRef}>
        <div className="d-flex justify-content-center align-items-center">
          <img src={FestDetails.logo} alt="logo" height="100px" />
          <div>
            <div className="ps-4">
              <h4>{FestDetails.name}</h4>
              <h5>{dateStrForFestival(FestDetails)}</h5>
              <h5>{FestDetails.venue}</h5>
            </div>
          </div>
        </div>
        <hr />
        <h4 className="text-center border-top border-bottom border-dark ">
          {collegeName}
        </h4>
        <hr />
        <div className="container">
          <div className="p-2 mt-1 mb-3 border bg-warning rounded">
            <b>NOTE: </b>
            All pages should have seal and signature of the Principal, otherwise
            the applications will be rejected.
          </div>
          <h5 className="text-center">Declaration</h5>
          <p>
            We, hereby declare that all the details entered in this document is
            correct.
          </p>
          <p>
            We, hereby verify that all the below mentioned students are part of
            this institution and are legally qualified to represent this
            institution in the {FestDetails.name}.
          </p>
          <div className="py-5 mt-5" style={{ width: "200px" }}>
            <div className="border-top border-dark p-0 m-0"></div>
            (Staff Advisor)
          </div>
          <div className="py-5 mt-5" style={{ width: "200px" }}>
            <div className="border-top border-dark p-0 m-0"></div>
            (Arts Club Secretary)
          </div>
          <div className="py-5 mt-5" style={{ width: "200px" }}>
            <div className="border-top border-dark p-0 m-0"></div>
            (Principal)
          </div>
        </div>
        <div className="page-break"></div>
        <hr />
        <hr />
        <h4 className="text-center border-top border-bottom border-dark ">
          {collegeName}
        </h4>
        <hr />
        <h5>
          Students <br />
          <small>Total: {allStudents?.data.count}</small>
        </h5>
        <hr />
        <div className="container-fluid">
          <div className="row">
            {allStudents?.data.results.map((st: any, index: number) => {
              return (
                <div
                  className="d-flex border border-secondary rounded p-2 me-3 mb-3"
                  style={{ height: "200px", width: "45%" }}
                >
                  <div style={{ width: "100px" }}>
                    <img src={st.photo} className="img-fluid" alt="student" />
                  </div>
                  <div className="ps-2 w-100">
                    <h5>{st.name}</h5>
                    <div>
                      <div className="row">
                        <div className="col-3">Gender</div>
                        <div className="text-capitalize col">{st.gender}</div>
                      </div>
                      <div className="row">
                        <div className="col-3">DOB</div>
                        <div className="col">{st.date_of_birth}</div>
                      </div>
                      <div className="row">
                        <div className="col-3">Phone</div>
                        <div className="col">{st.phone_number}</div>
                      </div>
                      <div className="row">
                        <div className="col-3">Admsn</div>
                        <div className="col"> {st.admission_number}</div>
                      </div>
                      <div className="row">
                        <div className="col-3">Class</div>
                        <div className="col">{st.class_name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="page-break"></div>
        <hr />
        <hr />
        <h4 className="text-center border-top border-bottom border-dark ">
          {collegeName}
        </h4>
        <hr />
        <h5>Events</h5>
        <hr />
        <div className="container-fluid">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Category</th>
                <th>Event</th>
                <th style={{ width: "50%" }}>Team Members</th>
              </tr>
            </thead>
            <tbody>
              {allTeams?.data.results.map((t: any, index: number) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{t.event.category.name}</td>
                  <td>{t.event.name}</td>
                  <td>
                    <b>Participants</b>
                    <ol>
                      {t.students.map((s: any) => (
                        <li>
                          <p className="p-0 m-0 fw-bold lh-sm">{s.name}</p>
                          <p className="p-0 m-0 fw-light lh-sm">
                            <small>{s.class_name}</small>
                          </p>
                        </li>
                      ))}
                    </ol>
                    {t.accompany_students && t.accompany_students?.length !== 0 && (
                      <>
                        
                        <hr />
                        Accompany:{" "}
                        {/* <b>{changeCase.capitalCase(t.accompany_method)}</b>{" "} */}
                        {/* <br /> */}
                        <ol>
                          {t.accompany_students.map((s: any) => (
                            <li>
                              <p className="p-0 m-0 fw-bold lh-sm">{s.name}</p>
                              <p className="p-0 m-0 fw-light lh-sm">
                                <small>{s.class_name}</small>
                              </p>
                            </li>
                          ))}
                        </ol>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PrintOutPage;
