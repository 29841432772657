//@ts-nocheck
import React, { useEffect, useState } from "react";
import Page from "../Page";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import {
  listStudents,
  QUERY_KEY as StudentsQueryKey,
  deleteStudent,
  updateStudent,
  generateParticipantCardForStudent,
} from "../data/student";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";
import { hasConfirmation, useHasConfirmed } from "../data/team";
import HasConfirmedBlocker from "../team/HasConfirmedBlocker";
import Pagination from "../components/Pagination";
import { useIsAdmin, useIsCollege } from "../data/auth";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import { formToJson } from "../data/api";
import {
  BaseApiStatus,
  TApiStatusResponse,
  setApiStatus,
} from "../utils/apiStatus";
import ResponseAlert from "../components/ResponseAlert";

const AddStudentButton = ({ disabled }) => (
  <Link to="new">
    <Button type="button" disabled={disabled}>
      Add Student
    </Button>
  </Link>
);

const StudentIndex: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(undefined);
  const [showDeleteToast, setShowDeleteToast] = useState({
    show: false,
    deletedUserName: undefined,
    error: undefined,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [studentToEditPhoneNumber, setStudentToEditPhoneNumber] = useState({});
  const [updatStudentStatus, setUpdateStudentStatus] = useState({
    showAlert: false,
    loading: false,
  });
  const [participantCardGenerationStatus, setParticipantCardGenerationStatus] =
    useState<TApiStatusResponse>(BaseApiStatus);

  const confirmationStatus = useHasConfirmed();
  const isAdminUser = useIsAdmin();
  const isCollegeUser = useIsCollege();

  const queryClient = useQueryClient();

  const getParams = () => {
    const params = {
      page: pageNumber,
      page_size: 20,
    };
    if (searchQuery) {
      params.search = searchQuery;
    }
    return params;
  };
  const { data: students, ...studentsMeta } = useQuery(
    [StudentsQueryKey, getParams()],
    listStudents,
    {
      staleTime: Infinity,
    }
  );
  const studentsMap = keyBy(students?.data.results, "id");

  const debouncedSearch = debounce((event) => {
    const query = event.target.value;
    if (query.length >= 3) setSearchQuery(query);
    else setSearchQuery(undefined);
    setPageNumber(1);
  }, 500);

  const handleGenerateParticipantCards = (studentId) => {
    setApiStatus(
      generateParticipantCardForStudent,
      { studentId },
      setParticipantCardGenerationStatus
    );
  };

  useEffect(() => {
    if (participantCardGenerationStatus.success) {
      window.open(
        participantCardGenerationStatus.success.response.card_url,
        "_blank"
      );
    }
  }, [participantCardGenerationStatus]);

  const handleClickOnDelete = (userId) => () => {
    setUserToDelete(userId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    const deletedUserName = studentsMap[userToDelete]?.name;
    if (confirmationStatus.hasConfirmed) {
      return;
    } else {
      deleteStudent(userToDelete)
        .then((response) => {
          if (response.status === 204) {
            setShowDeleteToast({
              show: true,
              deletedUserName,
              error: undefined,
            });
            queryClient.invalidateQueries(StudentsQueryKey);
          } else {
            setShowDeleteToast({
              show: true,
              deletedUserName: undefined,
              error: response?.data,
            });
          }
        })
        .catch(() =>
          setShowDeleteToast({
            show: true,
            deletedUserName: undefined,
            error: "Some error occurred.",
          })
        )
        .finally(() => {
          setShowDeleteModal(false);
        });
    }
  };

  const handleDeleteCanceled = () => {
    setUserToDelete(undefined);
    setShowDeleteModal(false);
  };

  const handleUpdateStudent = (e) => {
    e.preventDefault();
    const data = formToJson(e);
    data.id = studentToEditPhoneNumber.id;

    setApiStatus(updateStudent, data, (data) => {
      setUpdateStudentStatus(data);
      if (!!data.success) {
        queryClient.invalidateQueries(StudentsQueryKey);
      }
    });
  };

  const RenderEvents = (events) => {
    const mapped = groupBy(events, (e) => e.category?.name);
    if (Object.keys(mapped).length === 0) return null
    return (
      <div>
        <b>Participating in</b> 
        <hr />
        
        {Object.keys(mapped).map((k) => (
          <span>
            <strong>{k}</strong>
            <ol>
              {mapped[k].map((e) => (
                <li>{e.name}</li>
              ))}
            </ol>
          </span>
        ))}
      </div>
    );
  };

  const RenderAccompanyEvents = (events) => {
    const mapped = groupBy(events, (e) => e.category?.name);
    if (Object.keys(mapped).length === 0) return null
    return (
      <div>
        <b>Accompany for</b> 
        <hr />
        {Object.keys(mapped).map((k) => (
          <span>
            <strong>{k}</strong>
            <ol>
              {mapped[k].map((e) => (
                <li>{e.name}</li>
              ))}
            </ol>
          </span>
        ))}
      </div>
    );
  };

  return (
    <Page title="Students">
      <HasConfirmedBlocker confirmationStatus={confirmationStatus} />
      <Modal show={showDeleteModal} onHide={handleDeleteCanceled}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirm you want to delete{" "}
          <strong>{studentsMap[userToDelete]?.name}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCanceled}>
            No, Cancel.
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Yes, Delete.
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mb-4 d-flex align-items-center">
        <div className="me-2 flex-grow-1">
          <Form.Control
            type="text"
            placeholder="Search student by name or registration ID"
            onChange={debouncedSearch}
          />
        </div>
        <div>
          <AddStudentButton disabled={confirmationStatus.hasConfirmed} />
        </div>
      </div>
      {showDeleteToast.show && showDeleteToast.deletedUserName ? (
        <Alert
          variant="success"
          onClose={() => setShowDeleteToast({ show: false })}
        >
          <p>
            Successfully deleted{" "}
            <strong>{showDeleteToast.deletedUserName}</strong>
          </p>
        </Alert>
      ) : null}
      {showDeleteToast.show && showDeleteToast.error ? (
        <Alert
          variant="danger"
          onClose={() => setShowDeleteToast({ show: false })}
        >
          <p>
            {showDeleteToast.error?.non_field_errors || "Some error occurred. "}
          </p>
        </Alert>
      ) : null}
      {studentsMeta.isLoading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : students?.data.count === 0 ? (
        <div className="w-75">
          <Card>
            <Card.Body>
              <Card.Title>No Students were added.</Card.Title>
              The first step is to add students. Once students are added, you
              can continue to team registration.
            </Card.Body>
            <Card.Footer>
              <AddStudentButton disabled={confirmationStatus.hasConfirmed} />
            </Card.Footer>
          </Card>
        </div>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                {isAdminUser && <th width="15%">College</th>}
                <th>Photo</th>
                <th>Details</th>
                <th>Events</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {students?.data.results.map((student) => (
                <tr>
                  {isAdminUser && <td>{student.college.name}</td>}
                  <td>
                    <div style={{ width: "100px" }}>
                      <img
                        src={student.photo}
                        className="img-fluid"
                        alt={student.name}
                      />
                    </div>
                  </td>
                  <td>
                    <strong>{student.name}</strong> <br />
                    {student.class_name} <br />
                    {student.gender} <br />
                    Ph: {student.phone_number}
                  </td>
                  <td>
                    {RenderEvents(student.events)}
                    {RenderAccompanyEvents(student.accompanying_events)}
                  </td>
                  <td>
                    {isCollegeUser && (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={handleClickOnDelete(student.id)}
                        disabled={confirmationStatus.hasConfirmed}
                        className="me-2"
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => setStudentToEditPhoneNumber(student)}
                    >
                      Edit
                    </Button>
                    {isAdminUser && (
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() =>
                          handleGenerateParticipantCards(student.id)
                        }
                      >
                        Card
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            currentPage={pageNumber}
            itemCount={students?.data?.count || 0}
            setPage={setPageNumber}
            itemsPerPage={20}
          />
        </>
      )}
      <Offcanvas
        show={!!studentToEditPhoneNumber?.id}
        onHide={() => setStudentToEditPhoneNumber({})}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <small>{studentToEditPhoneNumber?.college?.name}</small>
            <br />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex align-items-center">
            <div className="me-2">
              <img
                src={studentToEditPhoneNumber?.photo}
                alt={studentToEditPhoneNumber?.name}
                className="img-fluid"
                style={{ width: "100px" }}
              />
            </div>
            <div>
              <strong>{studentToEditPhoneNumber?.name}</strong>
              <br />
              <strong>DOB:</strong> {studentToEditPhoneNumber?.date_of_birth}
              <br />
              <strong>Class:</strong> {studentToEditPhoneNumber?.class_name}
              <br />
            </div>
          </div>
          <Form onSubmit={handleUpdateStudent}>
            <Form.Group className="mt-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                defaultValue={studentToEditPhoneNumber?.name}
                disabled={!isAdminUser && confirmationStatus.hasConfirmed}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="phone_number"
                type="text"
                defaultValue={studentToEditPhoneNumber?.phone_number}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                name="date_of_birth"
                type="date"
                defaultValue={studentToEditPhoneNumber?.date_of_birth}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <div className="d-grid">
                <Button type="submit">Save</Button>
              </div>
            </Form.Group>
          </Form>
          <div className="mb-2"></div>
          <ResponseAlert
            status={updatStudentStatus}
            successBody="Updated successfully"
            errorBody={
              updatStudentStatus?.error?.status === 400
                ? Object.values(updatStudentStatus?.error?.response)
                    .map((i) => i[0])
                    .join("<br />")
                : "Some error occurred"
            }
          />
        </Offcanvas.Body>
      </Offcanvas>
    </Page>
  );
};

export default StudentIndex;
