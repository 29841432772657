//@ts-nocheck
import Page from "../Page";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import { useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { addStudent, QUERY_KEY as StudentQueryKey } from "../data/student";
import { useNavigate } from "react-router";
import { useQueryClient } from "react-query";
import { useHasConfirmed } from "../data/team";
import HasConfirmedBlocker from "../team/HasConfirmedBlocker";
import {
  BaseApiStatus,
  LoadingStatus,
  setApiStatus,
  TApiStatusResponse,
} from "../utils/apiStatus";
import { Alert } from "react-bootstrap";

const NewStudent = (props) => {
  const [submitStatus, setSubmitStatus] =
    useState<TApiStatusResponse>(BaseApiStatus);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const confirmationStatus = useHasConfirmed();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (confirmationStatus.hasConfirmed) {
      return;
    } else {
      setSubmitStatus(LoadingStatus);
      const formData = new FormData(event.currentTarget);
      setApiStatus(addStudent, formData, (newStatus) => {
        setSubmitStatus(newStatus);
        if (newStatus.success) {
          queryClient.invalidateQueries(StudentQueryKey);
          setTimeout(() => {
            navigate("/app/students/");
          }, 2000);
        }
      });
    }
  };

  return (
    <Page title="Add Student">
      <HasConfirmedBlocker confirmationStatus={confirmationStatus} />
      <Form onSubmit={handleSubmit}>
        <fieldset disabled={confirmationStatus.hasConfirmed}>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Ex. Rahul"
                  required
                />
                <Form.Text>
                  Full name of the student. This name will be used in the
                  certificates.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Select name="gender" required>
                  <option value="FEMALE">Female</option>
                  <option value="MALE">Male</option>
                  <option value="TRANSGENDER">Transgender</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="date_of_birth"
                  placeholder="22/02/2022"
                  required
                />
                <Form.Text>As per records.</Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="text"
                  name="phone_number"
                  placeholder="Ex. 9876543210"
                  minLength={10}
                  maxLength={10}
                  required
                />
                <Form.Text>
                  Phone number is used to uniquely identify the student. Please
                  enter the phone number of the student.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  name="admission_number"
                  placeholder="Ex. 1234"
                  required
                />
                <Form.Text>
                  Registration number provided by the University.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Class and Year</Form.Label>
                <Form.Control
                  type="text"
                  name="class_name"
                  placeholder="Eg. First Year B.Sc. Physics"
                  required
                />
                <Form.Text>Class name along with the year.</Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Photo</Form.Label>
                <Form.Control type="file" name="photo" required />
                <Form.Text>
                  Clear visible photo. Minimum 400h x 300w in pixels. Maximum
                  128kb size. Accepts .jpg, .png
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>ID Card</Form.Label>
                <Form.Control type="file" name="id_proof" required />
                <Form.Text>
                  College ID Card, or letter with photo attested by Principal.
                  Maximum 128kb size. Accepts .jpg, .png
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <div className="d-grid">
                <Button type="submit" disabled={submitStatus.loading}>
                  <div className="flex flex-items-center">
                    {submitStatus.loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Add Student"
                    )}
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
          {submitStatus.showAlert && (
            <Row className="mt-4">
              {submitStatus.success ? (
                <Alert variant="success">Student added successfully.</Alert>
              ) : (
                <Alert variant="danger">
                  Could not add student.
                  <br />
                  {submitStatus.error?.status === 400 && (
                    <div>
                      {Object.keys(submitStatus.error?.response || {}).map(
                        (key) => (
                          <div>
                            {key} -{" "}
                            {submitStatus.error?.response[key].join(",")}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </Alert>
              )}
            </Row>
          )}
        </fieldset>
      </Form>
    </Page>
  );
};

export default NewStudent;
