import { Card, Col, Container, Row } from "react-bootstrap";
import { FestDetails } from "../utils/static";
import {
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiOutlineFacebook,
} from "react-icons/ai";

const Website = () => {
  return (
    <div
      className="vw-100 website text-white"
      style={{ backgroundColor: "rgb(2 44 34)" }}
    >
      <div className="d-flex vh-100">
        {/* <div className="w-50 d-flex flex-column justify-content-center h-100">
          <h2 className="text-center">{FestDetails.name}</h2>
          <h4 className="text-center">{dateStrForFestival(FestDetails)}</h4>
          <h4 className="text-center">{FestDetails.venue}</h4>
        </div> */}
        <div
          className="w-100 d-flex align-items-center justify-content-center h-100"
          style={{ backgroundColor: "rgb(255 255 255)" }}
        >
          <img src={FestDetails.logo} alt="logo" height={500} width={400} />
        </div>
      </div>
      <div className="d-flex flex-wrap align-items-center mx-auto justify-content-center">
        <div></div>
      </div>
      {/* <Navbar collapseOnSelect expand="md">
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-center"
        >
          <Nav className="d-flex justify-content-center align-items-center">
            <Nav.Link href="/login">Register</Nav.Link>
            <Nav.Link href="#venue">Venue</Nav.Link>
            <Nav.Link href="#contact">Contact Us</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Navbar> */}

      <div
        style={{
          background: "url('/mg-university-bg.jpeg') rgba(2, 44, 34, 0.7)",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
        }}
      >
        <Container>
          <div
            className="d-flex flex-wrap flex-md-nowrap align-items-center mx-auto justify-content-center"
            style={{ paddingTop: "50px", paddingBottom: "50px" }}
          >
            <div>
              <h2>Kalolsavam 2024</h2>
              <p>
                Welcome to the Mahatma Gandhi University Arts Festival 2024 - a
                vibrant celebration of creativity, talent, and cultural
                diversity! Hosted by Mahatma Gandhi University Union, this
                annual extravaganza promises to be a kaleidoscope of artistic
                expressions, bringing together students from various disciplines
                to showcase their prowess in the realms of music, dance, drama,
                and visual arts.
              </p>
              <h2>Kottayam</h2>
              <p>
                4 വർഷത്തിന് ശേഷം മഹാത്മാഗാന്ധി സർവകലാശാല കലോത്സവത്തിന് കോട്ടയം
                ആതിഥേയരാകുന്നു. ഇന്ത്യയിലാദ്യമായി സമ്പൂർണ്ണസാക്ഷരത കൈവരിച്ച നഗരം
                കൂടിയായ കോട്ടയത്തെ "അക്ഷരനഗരി " എന്നും അറിയപ്പെടുന്നു.
                കോട്ടയത്തിന്റെ കേന്ദ്ര ബിന്ദുവായ തിരുനക്കരയാണ് 4 വർഷത്തിനുശേഷം
                കോട്ടയത്തേക്ക് കടന്നു വരുന്ന മഹാത്മാഗാന്ധി സർവകലാശാല
                കലോത്സവത്തിന്റെ പ്രധാന വേദി.കേരളത്തിലെയും, ദക്ഷിണേന്ത്യയിലെയും
                പ്രഥമ കലാലയമായ സി.എം.എസ്.കോളേജ് കലോത്സവത്തിന്റെ രണ്ടാം വേദിയായി
                അണിഞ്ഞൊരുങ്ങും.കോട്ടയത്തിന്റെ ഹൃദയഭാഗത്തു സ്ഥിതി ചെയ്യുന്ന
                ബസേലിയസ് കോളേജും,കോട്ടയത്തെ വനിതാ കലാലയം എന്ന പെരുമയുള്ള ബിസിഎം
                കോളേജും ഈ കലാമാമാങ്കത്തിന്റെ വേദികളാകും. മഹാത്മാഗാന്ധി സർവകലാശാല
                കലോത്സവത്തിന്റെ ഒരുപാട് ഓർമ്മകൾ ഇപ്പോഴും അലയടിക്കുന്ന കോട്ടയം
                നഗരം കലയുടെ പ്രതിരോധമായ "WE THE PEOPLE OF INDIA" എന്ന്
                പേരിട്ടിരിക്കുന്ന കലാമാമാങ്കത്തിനൊരുങ്ങിക്കഴിഞ്ഞു.
              </p>
              <div>
                <a
                  style={{ backgroundColor: "rgb(255 255 255)" }}
                  className="p-2 ps-4 pe-4 me-2 text-black rounded "
                  href="/login"
                >
                  Register Now
                </a>
                <a
                  style={{ backgroundColor: "rgb(255 255 255)" }}
                  className="p-2 ps-4 pe-4 me-2 text-black rounded "
                  href="https://kalotsavam.sgp1.cdn.digitaloceanspaces.com/assets/mgu-2024/kalotsavam-manual.pdf"
                >
                  Download Manual
                </a>
              </div>
            </div>
            <div
              style={{ backgroundColor: "rgba(255 255 255)" }}
              className="p-4 m-4"
            >
              <img
                src="https://kalotsavam.sgp1.cdn.digitaloceanspaces.com/assets/mgu-2024/mgu-logo.png"
                alt="logo"
                height={300}
              />
            </div>
          </div>
        </Container>
      </div>
      <div
        style={{ backgroundColor: "rgb(255 255 255)" }}
        className="pt-4 pb-4"
      >
        {" "}
        <Container className="text-black" id="venue">
          <h2>Venue</h2>
          <Row xs={1} md={2} lg={4}>
            <Col>
              <Card>
                <Card.Img
                  variant="top"
                  src="/thirunakkara-maidanam.avif"
                  height={250}
                />
                <Card.Body>
                  <Card.Title>Thirunakkara Maidanam</Card.Title>
                  <Card.Link href="https://maps.app.goo.gl/ZiWt8mZgai2DBg1H6">
                    Navigate
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img
                  variant="top"
                  src="/mgu-college-01.jpeg"
                  height={250}
                />
                <Card.Body>
                  <Card.Title>C M S College</Card.Title>
                  <Card.Link href="https://maps.app.goo.gl/hvY1qh1HdnnXitAq8?g_st=ic">
                    Navigate
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img
                  variant="top"
                  src="/mgu-college-02.jpeg"
                  height={250}
                />
                <Card.Body>
                  <Card.Title>Baselius College</Card.Title>
                  <Card.Link href="https://maps.app.goo.gl/juYRQenDwWEECR139">
                    Navigate
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img
                  variant="top"
                  src="/mgu-college-03.jpeg"
                  height={250}
                />
                <Card.Body>
                  <Card.Title>B C M College</Card.Title>
                  <Card.Link href="https://maps.app.goo.gl/ZiWt8mZgai2DBg1H6">
                    Navigate
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container className="pt-4 pb-4 text-black" id="contact">
          <Row className="g-4" xs={1} md={2}>
            <Col>
              <h2 className="mt-4">Organising Committee</h2>
              <Row className="gap-4" xs={1} md={2}>
                <Col>
                  <b>Melbin Joseph</b>
                  <br />
                  (General convener)
                  <br />
                  +918943680720
                </Col>
                <Col>
                  <b>Ashik B</b>
                  <br />
                  (Programme committee convenor)
                  <br />
                  +917356709363
                </Col>
              </Row>
            </Col>
            <Col>
              <h2 className="mt-4">University Union</h2>
              <Row className="gap-4" xs={1} md={2}>
                <Col>
                  <b>Rahul Mon Rajan</b>
                  <br />
                  (Chairperson, MG university union)
                  <br />
                  +919061199735
                </Col>
                <Col>
                  <b>Ajin Thomas</b>
                  <br />
                  (General Secretary, MG university union)
                  <br />
                  +918606665731
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          background: "url('/mg-university-bg.jpeg') rgba(0, 0, 0, 0.6)",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
        }}
      >
        <Container className="pb-4 pt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-4">
              <div className="mt-4">
                <img
                  src={FestDetails.logo}
                  alt="logo"
                  height={160}
                  width={160}
                  className="bg-white rounded-circle"
                />
              </div>
              <div>
                <h2>Follow Us</h2>
                <div className="d-flex gap-2" style={{ fontSize: "32px" }}>
                  <a
                    className="text-white"
                    href="https://www.instagram.com/wethepeopleofindia__/"
                  >
                    <AiOutlineInstagram />
                  </a>
                  <a
                    className="text-white"
                    href="https://chat.whatsapp.com/DpKqsWi26XIBv2oiijQ4VE"
                  >
                    <AiOutlineWhatsApp />
                  </a>
                  <a
                    className="text-white"
                    href="https://www.facebook.com/profile.php?id=61556252042107"
                  >
                    <AiOutlineFacebook />
                  </a>
                </div>
              </div>
            </div>
              <div>
                <h2>Contact Us</h2>
                <h4>For Registration and Technical Support</h4>
                <div className="d-flex flex-wrap gap-4">
                <div>
                    <b>Vyshavi Shaji</b>
                    <br />
                    (Convenor, Registration Committee)
                    <br />
                    +91 9744867573
                  </div>
                  <div>
                    <b>Muhammed Abbas S</b>
                    <br />
                    +91 9947030270
                  </div>
                  <div>
                    <b>Ruben Shaji</b>
                    <br />
                    +919544372957
                  </div>
                </div>
              </div>
          </div>
        </Container>
        <div style={{ background: "rgba(0, 0, 0, 0.5" }}>
          <Container className="pt-2 pb-2">
            <div className="d-flex justify-content-center">
              Hosted with{" "}
              <a href="https://tiqr.events" className="ps-2 text-white">
                TiQR Events
              </a>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Website;
