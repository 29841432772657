//@ts-nocheck
import Page from "../Page";
import { useQuery } from "react-query";
import { useState } from "react";
import {
  QUERY_KEY as ResultQueryKey,
  deleteResult,
  listResults,
} from "../data/result";
import Form from "react-bootstrap/esm/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import Table from "react-bootstrap/esm/Table";
import Pagination from "../components/Pagination";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { formToJson } from "../data/api";
import Modal from "react-bootstrap/Modal";
import { listEvents, QUERY_KEY as EventQueryKey } from "../data/event";
import keyBy from "lodash/keyBy";

const ResultIndex = () => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(undefined);
  const { data: events, ...eventMeta } = useQuery(EventQueryKey, listEvents, {
    staleTime: Infinity,
  });
  const eventsMap = keyBy(events?.data.results ?? [], "id");

  const [showDeleteToast, setShowDeleteToast] = useState({
    show: false,
    deletedEventName: undefined,
    error: undefined,
  });

  const navigate = useNavigate();

  const getParams = () => {
    const params = { page };
    if (searchQuery.length > 2) {
      params.search = searchQuery;
    }
    return params;
  };
  const { data: resultsResponse, ...resultsMeta } = useQuery(
    [ResultQueryKey, getParams()],
    listResults
  );
  const handleSearch = (e) => {
    e.preventDefault();
    const data = formToJson(e);
    setSearchQuery(data.search);
  };

  const resultItemCell = (resultItem) => (
    <>
      <td>{resultItem.position}</td>
      <td>{resultItem.team.college.name}</td>
      <td>{resultItem.grade}</td>
      <td>{resultItem.points}</td>
    </>
  );

  const handleClickOnDelete = (userId) => () => {
    setEventToDelete(userId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    const deletedEventName = events[eventToDelete]?.name;
    deleteResult(eventToDelete)
      .then((response) => {
        if (response.status === 204) {
          setShowDeleteToast({
            show: true,
            deletedEventName: deletedEventName,
            error: undefined,
          });
          queryClient.invalidateQueries(ResultQueryKey);
        } else {
          setShowDeleteToast({
            show: true,
            deletedEventName: undefined,
            error: response?.data,
          });
        }
      })
      .catch(() =>
        setShowDeleteToast({
          show: true,
          deletedEventName: undefined,
          error: "Some error occurred.",
        })
      )
      .finally(() => {
        setShowDeleteModal(false);
      });
  };

  const handleDeleteCanceled = () => {
    setEventToDelete(undefined);
    setShowDeleteModal(false);
  };

  return (
    <Page title="Results">
      <Link to="./add" className="btn btn-primary mb-2">
        New Result
      </Link>
      <Form onSubmit={handleSearch}>
        <div className="d-flex mb-3">
          <div className="me-2 flex-grow-1">
            <Form.Group>
              <Form.Control name="search" placeholder="Search for event" />
              <Form.Text>
                Use keywords - event, college, student. Eg: college:Govt College
              </Form.Text>
            </Form.Group>
          </div>
          <div>
            <Button type="submit">Search</Button>
          </div>
        </div>
      </Form>
      <Modal show={showDeleteModal} onHide={handleDeleteCanceled}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirm you want to delete the results for{" "}
          <strong>{eventsMap[eventToDelete]?.name}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCanceled}>
            No, Cancel.
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Yes, Delete.
          </Button>
        </Modal.Footer>
      </Modal>
      {resultsMeta.isLoading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Table hover striped bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Category</th>
                <th>Event</th>
                <th>Position</th>
                <th>College</th>
                <th>Grade</th>
                <th>Points</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {resultsResponse?.data.results.map((row, idx) => (
                <>
                  <tr>
                    <td valign="middle" rowSpan={row.items.length}>
                      {idx + 1}
                    </td>
                    <td valign="middle" rowSpan={row.items.length}>
                      {row.event.category.name}
                    </td>
                    <td valign="middle" rowSpan={row.items.length}>
                      {row.event.name}
                    </td>
                    {resultItemCell(row.items[0])}
                    <td rowSpan={row.items.length}>
                      <Button onClick={() => navigate(`./${row.event.id}`)}>
                        Print
                      </Button>
                      <Button
                        onClick={handleClickOnDelete(row.event.id)}
                        className="btn-danger"
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                  {row.items.slice(1).map((resultItem) => (
                    <tr>{resultItemCell(resultItem)}</tr>
                  ))}
                </>
              ))}
            </tbody>
          </Table>
          <Pagination
            currentPage={page}
            itemCount={resultsResponse?.data?.count || 0}
            setPage={setPage}
            itemsPerPage={20}
          />
        </>
      )}
    </Page>
  );
};

export default ResultIndex;
